var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

var feedTitleLinks = ['/Pages/Events/Category/events', '/Pages/News/'];
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-nasme'];
// var subsiteTitleNotClickable = true;


// Search container - Open on click
// $('.searchContainer input').click(function(){
//   $(this).parent().addClass('open');
// });


// Subsite - Annual Report
if( $('body.subsite[class*="annual-report"]').length ) {

  if( $('body.subsite[class*="homepage"]').length ) {
    $( "p" ).addClass( "text" );
    $('table').each(function () {
      $(this).find('p').removeClass("text");
      $(this).find('img').addClass("imageInTable");
    });
  

    //  Card style
    $('.homeFeedsnippets .feedItem').each(function() {
      var $this =  $(this);

      var $cardBanner = $this.find('.mediaImage:not(.imageInTable');
      var $cardTable = $this.find('table');
      var $cardText = $this.find('p.text');

      if (($cardBanner).length === 1) {
        $cardBanner.prependTo($this).wrap('<a class="home-feed-image-link"></a>');
        $this.addClass('cardOneImage');
      }
      if (($cardBanner).length === 2) {
        $this.addClass('cardTwoImages');
        $this.addClass('cardNoDetails');
      }

      if (($cardText).length) {
        $this.addClass('cardText');
      }

      if (($cardText).length === 0) {
        $this.addClass('cardNoText');
      }

      if (($cardTable).length === 1) {
        $this.addClass('cardTable');
        if (($cardBanner).length === 0) {
          $this.addClass('cardTable');
          if (($cardText).length) {
            $cardTable.prependTo($this).wrap('<a class="home-feed-image-link"></a>');
          }
        }
      }

    });

  }

  // // Home Feed - Slick slider
  $('.homeFeed[class*="team"], .homeFeed[class*="estimonial"], .homeFeed[class*="wards"][class*="creditation"], .homeFeed[class*="ase"][class*="tudies"]').find('.feedList').slick({
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    autoplay: true,
    autoplaySpeed: 8000,
  });
}


// Meet the team page - Making posts hover only - no click
$('.listedPost.PostCategory_unclickable').each(function () {
  $(this).find('a').removeAttr("href");
  $(this).find('a').prop("onclick", null).css("cursor", "auto");
  $(this).prop("onclick", null).css("cursor", "auto");
});